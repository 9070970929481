function locationSidebar() {
	if (window.matchMedia('(max-width: 991.98px)').matches) {
		$(".locatie-detail .album").css(
			"margin-top",
			"auto"
		);
	} else {
		$(".locatie-detail .album").css(
			"margin-top",
			$(".locatie-detail .sidebar").height() * -1 - 40
		);
	};
}

$(document).ready(function () {
	/*======  ON LOAD EVENTS  ======*/

	$('[data-toggle="tooltip"]').tooltip()

	/* =====  MOBILE MENU TOGGLE  ====== */
	$('.offCanvasToggler').click(function (event) {
		event.preventDefault();
		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	/*----------  LOCATION DETAIL SPACING  ----------*/

	locationSidebar();

	/*----------  DATEPICKER INIT  ----------*/

	$('.datepicker').pickadate({
		// Strings and translations
		monthsFull: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Maart', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
		weekdaysFull: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
		weekdaysShort: ['Zon', 'Maa', 'Din', 'Woe', 'Don', 'Vrij', 'Zat'],
		today: 'Vandaag',
		clear: 'Wissen',
		close: 'Sluiten',
		labelMonthNext: 'Volgende maand',
		labelMonthPrev: 'Vorige maand',
		labelMonthSelect: 'Kies een maand',
		labelYearSelect: 'Kies een jaar',
		format: 'd mmmm, yyyy',
		selectMonths: true,
		firstDay: 1
	});

	/*----------  DATEPICKER INIT  ----------*/

	$('.timepicker').pickatime({
		clear: 'Wissen',
		format: 'HH:i',
		interval: 30,

		// Time limits
		min: [7, 0],
		max: [20, 0],

		// Root picker container
		container: undefined,

		// Hidden input container
		containerHidden: undefined,

		// Close on a user action
		closeOnSelect: true,
		closeOnClear: true,

		// Events
		onStart: undefined,
		onRender: undefined,
		onOpen: undefined,
		onClose: undefined,
		onSet: undefined,
		onStop: undefined
	});


	$('.newsletter').each(function (i, el) {
		$(this).on('submit', 'form', function (e) {
			e.preventDefault();
			var $el = $(this).closest('.newsletter');
			var $form = $(this);
			$.ajax({
				url: $form.attr('action'),
				data: $form.serialize(),
				dataType: 'json',
				type: 'post',
				success: function (data) {
					$('.text-danger', $form).remove();
					if (data.status == 'success') {
						var $message = $('<p class="text-success">' + data.message + '</p>');
						$message.hide();
						$form.hide().after($message);
						$message.fadeIn(400);
					} else if (data.status == 'error') {
						var $message = $('<p class="text-danger">' + data.message + '</p>');
						$message.hide();
						$form.append($message);
						$message.fadeIn(400);
					}
				}
			});
		});
	});



	/*======  SLIDERS  ======*/

	/*----------  HOME HEADER SLIDER  ----------*/

	$(".home-header").slick({
		arrows: true,
		dots: false,
		infinite: true,
		prevArrow: $(".home-header .slider-controls .prev-slide"),
		nextArrow: $(".home-header .slider-controls .next-slide"),
		cssEase: "cubic-bezier(0.860, 0.000, 0.070, 1.000)",
		speed: 750,
		autoplay: true,
		autoplaySpeed: 3500,
		fade: true,
		cssEase: 'linear'
	});

	/*----------  FULL WIDTH SLIDER  ----------*/

	$('.full-width-slider .slides').slick({
		arrows: true,
		dots: false,
		infinite: true,
		prevArrow: $('.full-width-slider .slider-controls .prev-slide'),
		nextArrow: $('.full-width-slider .slider-controls .next-slide')
	});

	/*======  GRIDS  ======*/

	/*----------  LOCATIES GRID  ----------*/

	/*var $results = $("#results").isotope({
		itemSelector: ".grid-item",
		percentPosition: true,
		masonry: {
			columnWidth: ".grid-sizer",
			gutter: 10
		}
	});
	$results.imagesLoaded().progress(function(){
		$results.isotope('layout');
	});*/

	/*----------  LOCATIE DETAIL FOTO GRID  ----------*/

	//imagesLoaded($(".locatie-grid"), function() {
	$(".locatie-grid").isotope({
		itemSelector: ".grid-item",
		percentPosition: true,
		masonry: {
			columnWidth: ".grid-sizer",
			gutter: 10
		}
	});
	//});

	/*======  ACTIONS  ======*/

	/*----------  FILTER SIDEBAR OPEN / CLOSE  ----------*/

	$(".filter-header").click(function (event) {
		event.preventDefault();
		$(this).toggleClass("active");
		var t = $(this).data("filters");
		$(".filter .filter-content[data-filters=" + t + "]").slideToggle(), $(this).toggleClass("active")
	});

	/*----------  FILTER BUTTON CLICK  ----------*/

	/*$(".filter-btn").click(function(event) {
		event.preventDefault();
		$(this).toggleClass("active");
	});*/

	/*----------  SIDEBAR TOGGLE  ----------*/

	$('.sidebar .sidebar-toggle').click(function (event) {
		event.preventDefault();
		if (window.matchMedia('(max-width: 991.98px)').matches) {
			$(this).toggleClass('show');
			$('.sidebar .sidebar-content').slideToggle();
		} else {
			$('.sidebar .sidebar-content').toggleClass('show');
			$(this).toggleClass('show');
		}
	});




	/*----------  INTERESSE TAGS WISSEN  ----------*/

	$('.tag-item .sluiten').click(function (event) {
		event.preventDefault();
		var parent = $(this).parent('.tag-item');
		parent.remove();
	});
});

$(window).resize(function (event) {
	locationSidebar();
});